// Fonts
// $font-family-body: "Roboto Condensed", sans-serif;
$font-family-body: "Atkinson Hyperlegible", serif;
$font-family-mono: "Inconsolata", monospace;
$font-family-tt: "Inconsolata", monospace;
$font-size: 18px;
$line-height: 1.725;
$page-width: 48rem;
// Logo
$logo-width: 50px;
$logo-height: 50px;
$logo-grayout: true;
// Colors
$colors: "dark" // white dark light classic
